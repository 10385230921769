/*
 * @Autor: yzr
 * @Date: 2021-07-19 11:13:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-20 15:05:21
 * @Description:
 */
export default [
  {
    path: '/cps_web',
    name: 'cps_web',
    meta: {
      title: '渠道分利',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: '/cps_web/lottery',
        name: 'lottery',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'activity',
            name: 'activity',
            component: () => import(
              /* webpackChunkName: "cps_lottery_activity" */ '../views/lottery/activity/index'
            ),
            meta: { title: '抽奖活动列表' },
          },
          {
            path: 'winning',
            name: 'winning',
            component: () => import(
              /* webpackChunkName: "cps_lottery_winning" */ '../views/lottery/winning/index'
            ),
            meta: { title: '消费者中奖列表' },
          },
          {
            path: 'exchange',
            name: 'exchange',
            component: () => import(
              /* webpackChunkName: "cps_lottery_exchange" */ '../views/lottery/exchange/index'
            ),
            meta: { title: '消费者兑换单管理列表' },
          },
        ],
        meta: { title: '消费者抽奖活动管理' },
      },
      {
        path: '/cps_web/participant',
        name: 'participant',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'terminal',
            name: 'terminal',
            component: () => import(
              /* webpackChunkName: "cps_roleManagement_terminal" */ '../views/participant/terminal/index'
            ),
            meta: { title: '分利终端列表' },
          },
          {
            path: 'dealer',
            name: 'dealer',
            component: () => import(
              /* webpackChunkName: "cps_roleManagement_dealer" */ '../views/participant/dealer/index'
            ),
            meta: { title: '分利经销商列表' },
          },
          {
            path: 'consumer',
            name: 'consumer',
            component: () => import(
              /* webpackChunkName: "cps_roleManagement_consumer" */ '../views/participant/consumer/index'
            ),
            meta: { title: '分利消费者列表' },
          },
          {
            path: 'blacklist',
            name: 'blacklist',
            component: () => import(
              /* webpackChunkName: "cps_roleManagement_blacklist" */ '../views/participant/blacklist/index'
            ),
            meta: { title: '消费者黑名单' },
          },
        ],
        meta: { title: '分利协议管理' },
      },
      // {
      //   path: 'execute/merchandise_valuation',
      //   name: 'merchandiseValuation',
      //   component: () => import(
      //     /* webpackChunkName: "cps_execute_merchandiseValuation" */ '../views/execute/merchandise_valuation/index'
      //   ),
      //   meta: { title: '控价商品定价' },
      // },
      {
        path: 'execute/package',
        name: 'package',
        component: () => import(/* webpackChunkName: "cps_execute_package" */ '../views/execute/package'),
        meta: { title: '包量任务' },
      },
      {
        path: 'execute/record_flow',
        name: 'recordFlow',
        component: () => import(/* webpackChunkName: "cps_execute_recordFlow" */ '../views/execute/record_flow'),
        meta: { title: '扫码记录流水' },
      },
      {
        path: 'execute/dealers_record_flow',
        name: 'dealers_record_flow',
        component: () => import(/* webpackChunkName: "cps_execute_recordFlow" */ '../views/execute/dealers_record_flow'),
        meta: { title: '经销商扫码记录' },
      },
      {
        path: 'execute/terminal_record_flow',
        name: 'terminal_record_flow',
        component: () => import(/* webpackChunkName: "cps_execute_recordFlow" */ '../views/execute/terminal_record_flow'),
        meta: { title: '终端扫码记录' },
      },
      {
        path: 'execute/customer_record_flow',
        name: 'customer_record_flow',
        component: () => import(/* webpackChunkName: "cps_execute_recordFlow" */ '../views/execute/customer_record_flow'),
        meta: { title: '消费者扫码记录' },
      },
      {
        path: 'execute/details_of_counterfeit_goods',
        name: 'details_of_counterfeit_goods',
        component: () => import(/* webpackChunkName: "cps_execute_recordFlow" */ '../views/execute/details_of_counterfeit_goods'),
        meta: { title: '扫码窜货明细报表' },
      },
      {
        path: 'execute/summary_of_counterfeit_goods',
        name: 'summary_of_counterfeit_goods',
        component: () => import(/* webpackChunkName: "cps_execute_recordFlow" */ '../views/execute/summary_of_counterfeit_goods'),
        meta: { title: '扫码窜货汇总报表' },
      },
      {
        path: 'execute/display_task',
        name: 'displayTask',
        component: () => import(/* webpackChunkName: "cps_execute_displayTask" */ '../views/execute/display_task'),
        meta: { title: '陈列任务' },
      },
      {
        path: 'execute/stores_resources',
        name: 'storesResources',
        component: () => import(/* webpackChunkName: "cps_execute_displayTask" */ '../views/execute/stores_resources'),
        meta: { title: '门店资源发放情况' },
      },
      {
        path: 'execute/warehousing_overview',
        name: 'warehousingOverview',
        component: () => import(/* webpackChunkName: "cps_execute_displayTask" */ '../views/execute/warehousing_overview'),
        meta: { title: '经销商扫码入库概览' },
      },
      {
        path: 'execute/channelCode_management',
        name: 'channelCodeManagement',
        component: () => import(/* webpackChunkName: "cps_execute_channelCode_management" */ '../views/execute/channelCode_management'),
        meta: { title: '渠道码库管理' },
      },
      {
        path: 'execute/code_value_query',
        name: 'codeValueQuery',
        component: () => import(/* webpackChunkName: "cps_execute_code_value_query" */ '../views/execute/code_value_query'),
        meta: { title: '码值查询' },
      },
      {
        path: 'execute/code_tracing',
        name: 'codeTracing',
        component: () => import(/* webpackChunkName: "cps_execute_code_tracing" */ '../views/execute/code_tracing'),
        meta: { title: '码溯源页面' },
      },
      {
        path: '/cps_web/treety',
        name: 'treety',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'modules',
            name: 'modules',
            component: () => import(/* webpackChunkName: "cps_treety_modules" */ '../views/treety/modules/index'),
            meta: { title: '协议模板' },
          },
          {
            path: 'treeties',
            name: 'treeties',
            component: () => import(
              /* webpackChunkName: "cps_treety_treeties" */ '../views/treety/treeties/index'
            ),
            meta: { title: '分利协议' },
          },
        ],
        meta: { title: '分利协议管理' },
      },
      {
        path: '/cps_web/consumer',
        name: 'consumer',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'consumerScan',
            name: 'consumerScan',
            component: () => import(
              /* webpackChunkName: "cps_consumer_consumerScan" */ '../views/consumer/consumerScan/index'
            ),
            meta: { title: '消费者扫码活动' },
          },
          {
            path: 'consumerScan_budget_report',
            name: 'consumerScan_budget_report',
            component: () => import(
              /* webpackChunkName: "cps_consumer_consumerScan_budget_report" */ '../views/consumer/consumerScan_budget_report/index'
            ),
            meta: { title: '消费者扫码活动预算报表' },
          },
          {
            path: 'reverseRewardReport',
            name: 'reverseRewardReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_reverseRewardReport" */ '../views/consumer/reverse_reward_report'
            ),
            meta: { title: '反向奖励汇总表' },
          },
          {
            path: 'reverseRewardDetailReport',
            name: 'reverseRewardDetailReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_reverseRewardDetailReport" */ '../views/consumer/reverse_reward_detail_report'
            ),
            meta: { title: '反向奖励明细表' },
          },
          {
            path: 'reverseRewardBeanchOfficeReport',
            name: 'reverseRewardBeanchOfficeReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_reverseRewardBeanchOfficeReport" */ '../views/consumer/reverse_reward_branch_office_report'
            ),
            meta: { title: '反向奖励分公司统计报表' },
          },
          {
            path: 'dealerScanningRewardReport',
            name: 'dealerScanningRewardReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_dealerScanningRewardReport" */ '../views/consumer/dealer_scanning_reward_report'
            ),
            meta: { title: '经销商扫码奖励汇总表' },
          },
          {
            path: 'dealerScanningRewardDetailReport',
            name: 'dealerScanningRewardDetailReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_dealerScanningRewardDetailReport" */ '../views/consumer/dealer_scanning_reward_detail_report'
            ),
            meta: { title: '经销商扫码奖励明细表' },
          },
          {
            path: 'rewardDealersForTerminalScanningAndStorageReport',
            name: 'rewardDealersForTerminalScanningAndStorageReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_rewardDealersForTerminalScanningAndStorageReport" */ '../views/consumer/reward_dealers_for_terminal_scanning_and_storage_report'
            ),
            meta: { title: '终端扫码入库奖励经销商汇总表' },
          },
          {
            path: 'rewardDealersForTerminalScanningAndStorageDetailReport',
            name: 'rewardDealersForTerminalScanningAndStorageDetailReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_rewardDealersForTerminalScanningAndStorageDetailReport" */ '../views/consumer/reward_dealers_for_terminal_scanning_and_storage_detail_report'
            ),
            meta: { title: '终端扫码入库奖励经销商明细表' },
          },
          {
            path: 'rewardsForScanningQRCodesForGroupBuyersReport',
            name: 'rewardsForScanningQRCodesForGroupBuyersReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_rewardsForScanningQRCodesForGroupBuyersReport" */ '../views/consumer/rewards_for_scanning_QR_codes_for_group_buyers_report'
            ),
            meta: { title: '团购商扫码奖励汇总表' },
          },
          {
            path: 'rewardsForScanningQRCodesForGroupBuyersDetailReport',
            name: 'rewardsForScanningQRCodesForGroupBuyersDetailReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_rewardsForScanningQRCodesForGroupBuyersDetailReport" */ '../views/consumer/rewards_for_scanning_QR_codes_for_group_buyers_detail_report'
            ),
            meta: { title: '团购商扫码奖励明细表' },
          },
          {
            path: 'rewardsForGroupBuyingDealersOutboundDeliveryReport',
            name: 'rewardsForGroupBuyingDealersOutboundDeliveryReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_rewardsForGroupBuyingDealersOutboundDeliveryReport" */ '../views/consumer/rewards_for_group_buying_dealers_outbound_delivery_report'
            ),
            meta: { title: '团购商经销商出库奖励汇总表' },
          },
          {
            path: 'rewardsForGroupBuyingDealersOutboundDeliveryDetailReport',
            name: 'rewardsForGroupBuyingDealersOutboundDeliveryDetailReport',
            component: () => import(
              /* webpackChunkName: "cps_consumer_rewardsForGroupBuyingDealersOutboundDeliveryDetailReport" */ '../views/consumer/rewards_for_group_buying_dealers_outbound_delivery_detail_report'
            ),
            meta: { title: '团购商经销商出库奖励明细表' },
          },
          {
            path: 'channelScanCodeReward',
            name: 'channelScanCodeReward',
            component: () => import(
              /* webpackChunkName: "cps_consumer_channelScanCodeReward" */ '../views/consumer/channelScanCodeReward/index'
            ),
            meta: { title: '渠道扫码奖励报表' },
          },
          {
            path: 'channelScanDetail',
            name: 'channelScanDetail',
            component: () => import(
              /* webpackChunkName: "cps_consumer_channelScanDetail" */ '../views/consumer/channelScanDetail/index'
            ),
            meta: { title: '渠道扫码奖励明细表' },
          },
          {
            path: 'consumerBottleOpening',
            name: 'consumerBottleOpening',
            component: () => import(
              /* webpackChunkName: "cps_consumer_consumerBottleOpening" */ '../views/consumer/consumerBottleOpening/index'
            ),
            meta: { title: '消费者开瓶红包报表' },
          },
        ],
        meta: { title: '消费者扫码活动管理' },
      },
      {
        path: '/cps_web/rights',
        name: 'rights',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'dealer_balance',
            name: 'dealer_balance',
            component: () => import(
              /* webpackChunkName: "cps_dealer_balance" */ '../views/rights/fee/dealer_balance/index'
            ),
            meta: { title: '经销商分利费用余额' },
          },
          {
            path: 'dealer_profit_detail',
            name: 'dealer_profit_detail',
            component: () => import(
              /* webpackChunkName: "cps_dealer_balance" */ '../views/rights/fee/dealer_profit_detail/index'
            ),
            meta: { title: '经销商分利费用明细' },
          },
          {
            path: 'dealer_fee_bill',
            name: 'dealer_fee_bill',
            component: () => import(
              /* webpackChunkName: "cps_dealer_balance" */ '../views/rights/fee/dealer_fee_bill/index'
            ),
            meta: { title: '经销商费用上账申请' },
          },
          {
            path: 'cash_unfreezing',
            name: 'cash_unfreezing',
            component: () => import(
              /* webpackChunkName: "cps_dealer_balance" */ '../views/rights/cash/cash_unfreezing/index'
            ),
            meta: { title: '经销商现金解冻申请' },
          },
          {
            path: 'dealer_acount',
            name: 'dealer_acount',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/fee/dealer_acount/index'
            ),
            meta: { title: '经销商分利费用上账' },
          },
          {
            path: 'terminal_balance',
            name: 'terminal_balance',
            component: () => import(
              /* webpackChunkName: "cps_dealer_balance" */ '../views/rights/fee/terminal_balance/index'
            ),
            meta: { title: '终端分利费用余额' },
          },
          {
            path: 'terminal_acount',
            name: 'terminal_acount',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/fee/terminal_acount/index'
            ),
            meta: { title: '终端分利费用核销' },
          },
          {
            path: 'balance',
            name: 'balance',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/redpacket/balance/index'
            ),
            meta: { title: '红包余额看板' },
          },
          {
            path: 'customer',
            name: 'customer',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/redpacket/customer/index'
            ),
            meta: { title: '客户红包余额' },
          },
          {
            path: 'changeflow',
            name: 'changeflow',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/redpacket/changeflow/index'
            ),
            meta: { title: '红包变动流水' },
          },
          {
            path: 'billing',
            name: 'billing',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/redpacket/billing/index'
            ),
            meta: { title: '红包上账记录' },
          },
          {
            path: 'terminalSMS',
            name: 'terminalSMS',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/redpacket/terminalSMS/index'
            ),
            meta: { title: '终端短信记录' },
          },
          {
            path: '/cps_web/rights/withdraw',
            name: 'withdraw',
            component: { render: (e) => e('router-view') },
            children: [
              {
                path: 'consumer_redpacket',
                name: 'consumer_redpacket',
                component: () => import(
                  /* webpackChunkName: "cps_consumer_redpacket" */ '../views/rights/redpacket/withdraw/consumer_redpacket/index'
                ),
                meta: { title: '消费者红包提现明细' },
              },
              {
                path: 'terminal_redpacket',
                name: 'terminal_redpacket',
                component: () => import(
                  /* webpackChunkName: "cps_terminal_redpacket" */ '../views/rights/redpacket/withdraw/terminal_redpacket/index'
                ),
                meta: { title: '终端门店红包提现明细' },
              },
              {
                path: 'dealer_redpacket',
                name: 'dealer_redpacket',
                component: () => import(
                  /* webpackChunkName: "cps_dealer_redpacket" */ '../views/rights/redpacket/withdraw/dealer_redpacket/index'
                ),
                meta: { title: '经销商红包提现明细' },
              },
              {
                path: 'terminal_withdrawal',
                name: 'terminal_withdrawal',
                component: () => import(
                  /* webpackChunkName: "cps_terminal_withdrawal" */ '../views/rights/redpacket/withdraw/terminal_withdrawal/index'
                ),
                meta: { title: '终端提现产品系列报表（财务专用）' },
              },
            ],
            meta: { title: '红包提现明细管理' },
          },
          {
            path: '/cps_web/rights/wechat',
            name: 'wechat',
            component: { render: (e) => e('router-view') },
            children: [
              {
                path: 'consumer_withdraw',
                name: 'consumer_withdraw',
                component: () => import(
                  /* webpackChunkName: "cps_consumer_withdraw" */ '../views/rights/redpacket/wechat/consumer_withdraw/index'
                ),
                meta: { title: '消费者红包提现明细' },
              },
              {
                path: 'terminal_withdraw',
                name: 'terminal_withdraw',
                component: () => import(
                  /* webpackChunkName: "cps_terminal_withdraw" */ '../views/rights/redpacket/wechat/terminal_withdraw/index'
                ),
                meta: { title: '终端红包提现明细' },
              },
              {
                path: 'dealer_withdraw',
                name: 'dealer_withdraw',
                component: () => import(
                  /* webpackChunkName: "cps_dealer_withdraw" */ '../views/rights/redpacket/wechat/dealer_withdraw/index'
                ),
                meta: { title: '经销商红包提现明细' },
              },
            ],
            meta: { title: '微信红包提现明细管理' },
          },
          {
            path: 'pointsbalance',
            name: 'pointsbalance',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/integral/pointsbalance/index'
            ),
            meta: { title: '客户积分余额' },
          },
          {
            path: 'integralflow',
            name: 'integralflow',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/integral/integralflow/index'
            ),
            meta: { title: '积分变动流水' },
          },
          {
            path: 'cashlflow',
            name: 'cashflow',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/cash/cashFlow/index'
            ),
            meta: { title: '经销商分利现金权益明细' },
          },
          {
            path: 'cashbalance',
            name: 'cashbalance',
            component: () => import(
              /* webpackChunkName: "cps_dealer_acount" */ '../views/rights/cash/cashBalance/index'
            ),
            meta: { title: '经销商分利现金权益明细' },
          },
        ],
        // meta: { title: '费用权益' },
        meta: { title: '分利权益兑付' },
      },
      {
        path: '/cps_web/contract',
        name: 'contract',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'terminal_contract',
            name: 'terminal_contract',
            component: () => import(
              /* webpackChunkName: "cps_contract_terminal_contract" */ '../views/contract/terminal_contract/index'
            ),
            meta: { title: '终端门店服务合同列表' },
          },
          {
            path: 'dealer_contract',
            name: 'dealer_contract',
            component: () => import(
              /* webpackChunkName: "cps_contract_dealer_contract" */ '../views/contract/dealer_contract/index'
            ),
            meta: { title: '经销商服务合同列表' },
          },
        ],
        meta: { title: '红包服务合同管理' },
      },
      {
        path: '/cps_web/configuration',
        name: 'configuration',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'redenvelope',
            name: 'redenvelope',
            component: () => import(
              /* webpackChunkName: "cps_treety_redenvelope" */ '../views/configuration/redenvelope/index'
            ),
            meta: { title: '红包权益配置' },
          },
          {
            path: 'ladder',
            name: 'ladder',
            component: () => import(
              /* webpackChunkName: "cps_treety_redenvelope" */ '../views/configuration/ladder/index'
            ),
            meta: { title: '协议阶梯管理' },
          },
          {
            path: 'random',
            name: 'random',
            component: () => import(
              /* webpackChunkName: "cps_treety_redenvelope" */ '../views/configuration/random/index'
            ),
            meta: { title: '定额随机值维护' },
          },
        ],
        meta: { title: '业务规则配置' },
      },
      {
        path: '/cps_web/feedback',
        name: 'feedback',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'feedbacklist',
            name: 'feedbacklist',
            component: () => import(
              /* webpackChunkName: "cps_treety_feedback" */ '../views/feedback/feedbacklist/index'
            ),
            meta: { title: '意见反馈管理列表' },
          },
        ],
        meta: { title: '意见反馈管理' },
      },
      {
        path: '/cps_web/group_buying',
        name: 'group_buying',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'ex_warehouse',
            name: 'ex_warehouse',
            component: () => import(
              '../views/group_buying/ex_warehouse'
            ),
            meta: { title: '团购活动管理' },
          },
          {
            path: 'customer_report',
            name: 'customer_report',
            component: () => import(
              '../views/group_buying/customer_report'
            ),
            meta: { title: '团购客户报表' },
          },
        ],
        meta: { title: '团购活动管理' },
      },
      {
        path: '/cps_web/haipu_terminal',
        name: 'haipu_terminal',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'cash_withdrawal_details',
            name: 'cash_withdrawal_details',
            component: () => import(
              '../views/haipu_terminal/cash_withdrawal_details'
            ),
            meta: { title: '海普终端提现明细' },
          },
          {
            path: 'contract_management',
            name: 'contract_management',
            component: () => import(
              '../views/haipu_terminal/contract_management'
            ),
            meta: { title: '海普终端服务合同管理' },
          },
        ],
        meta: { title: '海普终端' },
      },
      {
        path: '/cps_web/reward_pool',
        name: 'reward_pool',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'reward_rules',
            name: 'reward_rules',
            component: () => import(
              '../views/reward_pool/reward_rules'
            ),
            meta: { title: '奖励规则配置' },
          },
          {
            path: 'reward_rules_detail',
            name: 'reward_rules_detail',
            component: () => import(
              '../views/reward_pool/reward_rules_detail'
            ),
            meta: { title: '奖励规则明细' },
          },
          {
            path: 'reward_pool_balance',
            name: 'reward_pool_balance',
            component: () => import(
              '../views/reward_pool/reward_pool_balance'
            ),
            meta: { title: '奖励池余额管理' },
          },
          {
            path: 'reward_pool_detail',
            name: 'reward_pool_detail',
            component: () => import(
              '../views/reward_pool/reward_pool_detail'
            ),
            meta: { title: '奖励池明细' },
          },
        ],
        meta: { title: '奖励池管理' },
      },
    ],
  },
];
