export default [
  {
    name: 'contract',
    path: '/contract/manage',
    component: () => import('../views/contract/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'bill-check',
    path: '/bill/bill-check',
    component: () => import('../views/bill/bill-check'),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: 'channel-cost-config',
    path: '/channel-cost/config',
    component: () => import('../views/rebate/channel-cost-config/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'channel-cost-list',
    path: '/channel-cost/list',
    component: () => import('../views/rebate/channel-cost-list/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'channel-cost-apply',
    path: '/channel-cost/apply',
    component: () => import('../views/rebate/channel-cost-apply/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'capital-pool-typeB',
    path: '/capital-pool/typeB',
    component: () => import('../views/capital-pool/type-b/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'B类资金池明细',
    path: '/capital-pool/typeB/detail',
    component: () => import('../views/capital-pool/type-b-detail/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'capital-pool-cash',
    path: '/capital-pool/cash',
    component: () => import('../views/capital-pool/cash/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: '现金流资金池明细',
    path: '/capital-pool/cash/detail',
    component: () => import('../views/capital-pool/cash-detail/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'goods-replenish',
    path: '/capital-pool/goods-replenish',
    component: () => import('../views/capital-pool/goods-replenish/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: '货补池明细',
    path: '/capital-pool/goods-replenish/detail',
    component: () => import('../views/capital-pool/goods-replenish-detail/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: '货补池明细',
    path: '/capital-pool/goods-replenish/detail',
    component: () => import('../views/capital-pool/goods-replenish-detail/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'need-goods',
    path: '/order/need-goods/list',
    component: () => import('../views/order/need-goods/table/table'),
    meta: {
      keepAlive: false,
    },
  },

  {
    name: 'need_goods_simple',
    path: '/order/need-goods/need_goods_simple',
    component: () => import('../views/order/need_goods_simple/table/table'),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: 'need_goods_config',
    path: '/order/need-goods/need_goods_config',
    component: () => import('../views/order/need_goods_config'),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: 'need_goods_detail',
    path: '/order/need_goods_detail',
    component: () => import('../views/order/need_goods_detail/index'),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: 'low-price',
    path: '/other/low-price/list',
    component: () => import('../views/other/low-price/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'counterfeiting',
    path: '/other/counterfeiting/list',
    component: () => import('../views/other/counterfeiting/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'back',
    path: '/order/back/list',
    component: () => import('../views/order/back/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'anxious',
    path: '/order/anxious/list',
    component: () => import('../views/order/anxious/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'urgently_needed_detail',
    path: '/order/urgently_needed_detail',
    component: () => import('../views/order/urgently_needed_detail'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'send',
    path: '/order/send/list',
    component: () => import('../views/order/send/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'invoice',
    path: '/order/invoice/list',
    component: () => import('../views/order/invoice/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'quota_management',
    path: '/order/quota_management',
    component: () => import('../views/order/quota_management'),
    meta: {
      keepAlive: true,
      title: '配额管理',
    },
  }, {
    name: 'dealer_quota_management',
    path: '/order/dealer_quota_management',
    component: () => import('../views/order/dealer_quota_management'),
    meta: {
      keepAlive: true,
      title: '经销商配额管理',
    },
  }, {
    name: 'dealer_quota_report',
    path: '/order/dealer_quota_report',
    component: () => import('../views/order/dealer_quota_report'),
    meta: {
      keepAlive: true,
      title: '经销商配额报表',
    },
  },
  // 要货计划明细
  {
    name: 'demand_plan_detail',
    path: '/order/demand_plan_detail/list',
    component: () => import('../views/order/demand_plan_detail/table'),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: 'significance',
    path: '/order/significance/list',
    component: () => import('../views/order/significance/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'remittance',
    path: '/bill/remittance/list',
    component: () => import('../views/bill/remittance/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'reconciliation',
    path: '/bill/reconciliation/list',
    component: () => import('../views/bill/reconciliation/table/table'),
    meta: {
      keepAlive: false,
    },
  }, {
    name: 'capital-account',
    path: '/bill/capital-account/list',
    component: () => import('../views/bill/capital-account/table/table'),
    meta: {
      keepAlive: false,
    },
  },
  {
    name: 'anxious_detail_report',
    path: '/order/anxious_detail_report',
    component: () => import('../views/order/anxious_detail_report/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 货权转移
  {
    name: 'cargo',
    path: '/InventoryManagement/cargo',
    component: () => import('../views/InventoryManagement/cargo/table/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 库存汇总表
  {
    name: 'inventoryCollect',
    path: '/InventoryManagement/inventoryCollect',
    component: () => import('../views/InventoryManagement/inventoryCollect/table/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 进销存明细表
  {
    name: 'saleInventory',
    path: '/InventoryManagement/saleInventory',
    component: () => import('../views/InventoryManagement/saleInventory/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 盘点
  {
    name: 'inventory',
    path: '/InventoryManagement/Inventory',
    component: () => import('../views/InventoryManagement/Inventory/table/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 盘点明细表
  {
    name: 'InventoryDetail',
    path: '/InventoryManagement/InventoryDetail',
    component: () => import('../views/InventoryManagement/InventoryDetail/table/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 分利协议管理-配置列表
  {
    name: 'configuration',
    path: '/sharing_agreement/configuration_list',
    component: () => import('../views/sharing_agreement/configuration_list/table/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 再来一瓶
  {
    name: 'another_bottle',
    path: '/anotherBottle/list',
    component: () => import('../views/anotherBottle/list/table/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 终端标签
  {
    name: 'terminal_label',
    path: '/terminal_label',
    component: () => import('../views/terminal_label/table/index'),
    meta: {
      keepAlive: false,
    },
  },
  // 终端入库详情
  {
    name: 'terminal_scan_in_record',
    path: '/terInInfo',
    component: () => import('../views/terInInfo/table/index'),
    meta: {
      keepAlive: false,
    },
  },
];
